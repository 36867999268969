(function(site){
	"use strict";

	function init() {
		
		render();
	}

	function render() {
		

	}

	// Public API definitions
	site.home = {
		data:[]
	};

	$(function(){
		init();
	});
})(window.site=window.site || {});
